'use strict'

###*
 # @ngdoc object
 # @name mundoReporting.service:ReportMapService

 # @description

###
class ReportMapService
  ### @ngInject ###
  constructor:(
    $log
    $filter
    $q
    MundoMap
    $document
    $window
  ) ->

    ## Get the openlayer object from the window
    ol = $window.ol

    ## Returns an ol map instance create with the MundoMap Factory
    @getMap = (mapId) ->
      MundoMap.createInstance mapId,
        layers: [
          #   _layerType: 'Vector'
          #   _layerId: 'markers'
          #   _weight: 50
          #   _clusterable: true
          #   _searchable: false
          #   _zoomable: true
          #   _updateWhileMoving: true
          #   title: 'Markers (Base)'
          #   visible: true
          #   source: [
          #     'Vector'
          #       features: []
          #   ]
          #   style: null
          # ,
            _layerType: 'Vector'
            _layerId: 'links'
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            _weight: 55
            title: 'Links'
            visible: true
            source: [
              'Vector'
                features: []
            ]
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'links_embedded'
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            _weight: 54
            title: 'Links (embedded)'
            visible: true
            source: [
              'Vector'
                features: []
            ]
            style: null
          ,
            _layerType: 'Vector'
            _layerId: 'trips'
            _weight: 50
            _clusterable: false
            _searchable: false
            _zoomable: true
            _updateWhileMoving: true
            title: 'Trips (Base)'
            visible: true
            wrapX: false
            source: [
              'Vector'
                features: []
            ]
            style: null
          ,
        ]
        follow:
          objects: []
          enabled: true
          speedZoom: false
        fillScreen: true
        search:
          enabled: false

    ## Returns the layer style for the link layer (Complete routes)
    @getLinkLayerStyle = (feature) ->
      style = feature.get('_cachedStyle')

      if not style
        style = [
          new ol.style.Style
            stroke: new ol.style.Stroke
              color: '#222222'
              width: 5
          new ol.style.Style
            stroke: new ol.style.Stroke
              color: "##{feature.get('_item')._meta.color}"
              width: 3
        ]

        geometry = feature.get('geometry')
        fill = new ol.style.Fill
          color: 'white'
        stroke = new ol.style.Stroke
          color: "##{feature.get('_item')._meta.color}"
          width: 2

        skip = 0
        geometry.forEachSegment (end, start) ->
          if(skip == 8)
            dx = end[0] - start[0]
            dy = end[1] - start[1]
            rotation = Math.atan2(dy, dx)
            style.push new (ol.style.Style)(
              geometry: new ol.geom.Point end
              image: new ol.style.RegularShape
                fill: fill
                stroke: stroke
                points: 3
                radius: 5
                rotateWithView: true
                rotation: -rotation + (Math.PI / 2)
              )
            skip = 0
          else
            skip++
          return

        feature.set '_cachedStyle', style

      return style

    ## Returns the layer style for the marker layer
    @getMarkerLayerStyle = (feature) ->

      # Check whether this feature is a cluster or not
      cluster = feature.get('features')?

      if cluster
        size = feature.get('features').length

        # If the cluster size is larger than 1 (an actual cluster),
        # return a cluster style
        if size > 1
          style = feature.get('_cachedStyle')

          if not style
            style = new ol.style.Style
              image: new ol.style.Circle
                radius: 15
                stroke: new ol.style.Stroke
                  color: 'rgba(255, 255, 255, 0.8)'
                  width: 2
                fill: new ol.style.Fill
                  color: '#3399CC'
              text: new ol.style.Text
                text: size.toString()
                scale: 1.5
                fill: new ol.style.Fill
                  color: '#FFFFFF'

            feature.set '_cachedStyle', style
          return style

      # If the feature is not a cluster,
      # or if the feature is a cluster of only one feature,
      # render a regular feature
      feature = if cluster then feature.get('features')[0] else feature
      bgColor = "##{feature.get('_item')._meta.color}"
      textColor = '#222222'
      opacity = 0.8

      style = feature.get('_cachedStyle')

      featureType = feature.get('_field')

      if !featureType?
        featureType = "start.location.point"

      if not style
        if featureType is "stop.location.point"
          style = [
            new ol.style.Style
              image: new ol.style.RegularShape
                radius: 20
                points: 4
                rotation: (1/4) * Math.PI
                stroke: new ol.style.Stroke
                  color: 'rgba(0, 0, 0, 0.8)'
                  width: 3
                fill: new ol.style.Fill
                  color: 'rgba(255, 255, 255, 0.8)'
            new ol.style.Style
              image: new ol.style.RegularShape
                points: 4
                radius: 20
                rotation: (1/4) * Math.PI
                stroke: new ol.style.Stroke
                  # opacity: 0.7
                  # color: '#222222'
                  color: bgColor
                  width: 2
            new ol.style.Style
              image: new ol.style.Icon
                anchor: [0.5, 0.5]
                anchorXUnits: 'fraction'
                anchorYUnits: 'fraction'
                snapToPixel: true
                opacity: 1
                crossOrigin: 'anonymous'
                src: feature.get('_item')._meta.mapMarkerUrl
                color: bgColor
          ]

          feature.set '_cachedStyle', style

        else
          style = [
            new ol.style.Style
              image: new ol.style.Circle
                radius: 17
                stroke: new ol.style.Stroke
                  color: 'rgba(0, 0, 0, 0.8)'
                  width: 3
                fill: new ol.style.Fill
                  color: 'rgba(255, 255, 255, 0.8)'
            new ol.style.Style
              image: new ol.style.Circle
                radius: 17
                stroke: new ol.style.Stroke
                  # opacity: 0.7
                  # color: '#222222'
                  color: bgColor
                  width: 2
            new ol.style.Style
              image: new ol.style.Icon
                anchor: [0.5, 0.5]
                anchorXUnits: 'fraction'
                anchorYUnits: 'fraction'
                snapToPixel: true
                opacity: 1
                crossOrigin: 'anonymous'
                src: feature.get('_item')._meta.mapMarkerUrl
                color: bgColor
          ]

          feature.set '_cachedStyle', style

      return style

    ## Returns the layer style for the embedded links layer (Single points of a route)
    @getEmbeddedLinkLayerStyle = (feature) ->

      # Check whether this feature is a cluster or not
      cluster = feature.get('features')?

      if cluster
        size = feature.get('features').length

        # If the cluster size is larger than 1 (an actual cluster),
        # return a cluster style
        if size > 1
          style = feature.get('_cachedStyle')

          if not style
            style = new ol.style.Style
              image: new ol.style.Circle
                radius: 12
                stroke: new ol.style.Stroke
                  color: 'rgba(255, 255, 255, 0.8)'
                  width: 2
                fill: new ol.style.Fill
                  color: '#56d992'
              text: new ol.style.Text
                text: size.toString()
                scale: 1.2
                fill: new ol.style.Fill
                  color: '#FFFFFF'

            feature.set '_cachedStyle', style
          return style

      # If the feature is not a cluster,
      # or if the feature is a cluster of only one feature,
      # render a regular feature
      feature = if cluster then feature.get('features')[0] else feature
      bgColor = "##{feature.get('_item')._meta.color}"
      textColor = '#222222'
      opacity = 0.8

      style = feature.get('_cachedStyle')

      if not style
        style = [
            new ol.style.Style
              image: new ol.style.Circle
                radius: 7
                stroke: new ol.style.Stroke
                  color: 'rgba(0, 0, 0, 0.8)'
                  width: 3
                fill: new ol.style.Fill
                  color: 'rgba(255, 255, 255, 0.4)'
            new ol.style.Style
              image: new ol.style.Circle
                radius: 7
                stroke: new ol.style.Stroke
                  # opacity: 0.7
                  # color: '#222222'
                  color: bgColor
                  width: 2
            # new ol.style.Style
            #   image: new ol.style.Icon
            #     anchor: [0.5, 0.5]
            #     anchorXUnits: 'fraction'
            #     anchorYUnits: 'fraction'
            #     snapToPixel: true
            #     opacity: 1
            #     crossOrigin: 'anonymous'
            #     src: feature.get('_item')._meta.mapMarkerUrl
            #     color: bgColor
        ]

        feature.set '_cachedStyle', style

      return style

    # Resize the map to the page
    ## TODO: Fix a whole bunch of responsive stuff here
    @setMapHeight = (map) ->
      setTimeout () ->
        for x in document.querySelectorAll 'md-tab-content'
          tab = angular.element x
          container = x.querySelectorAll 'section.report-map-container'

          if (not container?) or (not container.length)
            continue

          domMap = container[0].querySelectorAll('.report-map-container .mundo-map.report-map')[0]
          domMap = angular.element(domMap)
          container = angular.element(container)

          # Scroll to top
          window.scrollTo 0, 0

          # Determine viewport height
          viewportHeight = window.innerHeight
          # Determine offset from top
          topOffset = container[0].getBoundingClientRect().top

          # Determine footer height
          footerHeight = tab[0].querySelectorAll('.report-table-footer')[0].offsetHeight

          # Subtract additional margins, etc..
          additionalHeight = 10

          # Determine maximum table height
          mapMaxHeight = viewportHeight - topOffset - additionalHeight - footerHeight

          # Ensure table height never drops below the minimum value
          mapMinimumHeight = 500
          mapMaxHeight = if mapMaxHeight < mapMinimumHeight then mapMinimumHeight else mapMaxHeight

          # Set max-height
          container.css 'max-height', "#{mapMaxHeight}px"
          domMap.css 'height', "#{mapMaxHeight}px"

          map.updateSize()
      , 20

angular
  .module('mundoReporting')
  .service 'ReportMapService', ReportMapService
